import React, { useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Text, Html } from '@react-three/drei';
import { FaPython, FaJsSquare, FaRobot, FaVrCardboard, FaCog, FaBrain } from 'react-icons/fa'; // Importing icons

const icons = {
  Python: FaPython,
  JavaScript: FaJsSquare,
  Automation: FaRobot,
  VR: FaVrCardboard,
  'HR Tech': FaCog,
  'AI/ML': FaBrain
};

const SkillOrb = ({ skill, index, totalSkills, radius, onHover, onLeave }) => {
  const orbRef = useRef();
  const [hovered, setHovered] = useState(false);
  const Icon = icons[skill.name]; // Getting the relevant icon

  useFrame(({ clock }) => {
    const angle = (index / totalSkills) * Math.PI * 2 + clock.getElapsedTime() * 0.2;
    orbRef.current.position.x = Math.cos(angle) * radius;
    orbRef.current.position.y = Math.sin(angle) * radius;
  });

  return (
    <group 
      ref={orbRef}
      onPointerOver={() => { setHovered(true); onHover(skill); }}
      onPointerOut={() => { setHovered(false); onLeave(); }}
    >
      <mesh>
        {/* Replacing sphereGeometry with Icon */}
        <Html position={[0, 0, 0]}>
          <div style={{ fontSize: '28px', color: hovered ? '#FFA500' : '#FFDD57' }}>
            <Icon />
          </div>
        </Html>
      </mesh>
      <Text
        position={[0, 0.3, 0]}
        fontSize={0.15}
        color="#FFFFFF"
        anchorX="center"
        anchorY="middle"
      >
        {skill.name}
      </Text>
    </group>
  );
};

const FloatingSkillRing = ({ position = [0, 0, 0], scale = 1 }) => {
  const groupRef = useRef();
  const [hoveredSkill, setHoveredSkill] = useState(null);
  const skills = [
    { name: 'Python', description: 'Expert in Python development and automation' },
    { name: 'JavaScript', description: 'Full-stack JS developer with React expertise' },
    { name: 'HR Tech', description: 'Innovating in Human Resources technology' },
    { name: 'Automation', description: 'Streamlining processes with cutting-edge automation' },
    { name: 'VR', description: 'Creating immersive VR experiences' },
    { name: 'AI/ML', description: 'Implementing AI and Machine Learning solutions' },
  ];

  useFrame(({ clock }) => {
    groupRef.current.rotation.z = Math.sin(clock.getElapsedTime() * 0.3) * 0.1;
  });

  return (
    <group ref={groupRef} position={position} scale={scale}>
      {skills.map((skill, index) => (
        <SkillOrb
          key={skill.name}
          skill={skill}
          index={index}
          totalSkills={skills.length}
          radius={2}
          onHover={setHoveredSkill}
          onLeave={() => setHoveredSkill(null)}
        />
      ))}
      {hoveredSkill && (
        <Html position={[0, 0, 0]}>
          <div className="bg-gray-800 text-white p-2 rounded-lg shadow-lg" style={{ width: '200px' }}>
            <h3 className="text-lg font-bold">{hoveredSkill.name}</h3>
            <p className="text-sm">{hoveredSkill.description}</p>
          </div>
        </Html>
      )}
    </group>
  );
};

export default FloatingSkillRing;
