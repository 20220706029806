import React from 'react';
import { motion } from 'framer-motion';
import { FaUsers, FaLaptopCode, FaProjectDiagram, FaCogs, FaRobot, FaBullseye } from 'react-icons/fa';

const competencies = [
  {
    name: 'HR Leadership',
    description: 'HR Jedi Master: turning chaos into order with just a flick of a spreadsheet and a well-timed team meeting.',
    icon: <FaUsers />,
  },
  {
    name: 'Technology Solutions',
    description: 'I speak Python, JavaScript, Unity, and VBA—basically, I’m a code whisperer.',
    icon: <FaLaptopCode />,
  },
  {
    name: 'Data Automation',
    description: 'Why do repetitive tasks when you can have a robot do it for you? Automation, baby!',
    icon: <FaRobot />,
  },
  {
    name: 'Project Management',
    description: 'Herding cats, juggling flaming swords, and still hitting deadlines. That’s project management for you.',
    icon: <FaProjectDiagram />,
  },
  {
    name: 'Operational Process Improvement',
    description: 'I don’t just improve processes—I make them leaner, faster, and cost less, like a ninja of efficiency.',
    icon: <FaCogs />,
  },
  {
    name: 'Goal-Oriented',
    description: 'Laser-focused on achieving results and targets, because "almost there" doesn’t cut it for me.',
    icon: <FaBullseye />,
  },
];

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: 'easeOut' } },
};

const Competencies = () => {
  return (
    <section id="competencies" className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white p-8 sm:p-12">
      <motion.h2
        className="text-4xl sm:text-5xl font-bold text-center text-yellow-400 mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Core Competencies
        <span className="text-base mt-2 flex justify-center">(or, My Secret Superpowers )</span>
      </motion.h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {competencies.map((comp, index) => (
          <motion.div
            key={index}
            className="relative p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-yellow-500/50 hover:shadow-2xl transition-transform duration-300 transform hover:scale-110 hover:rotate-2"
            initial="hidden"
            whileInView="visible"
            variants={cardVariants}
            viewport={{ once: true }}
          >
            <div className="text-yellow-400 text-5xl mb-4 hover:animate-spin">
              {comp.icon}
            </div>
            <h3 className="text-2xl sm:text-3xl font-bold text-white mb-2">
              {comp.name}
            </h3>
            <p className="text-lg text-gray-300">
              {comp.description}
            </p>
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent to-yellow-400 opacity-0 hover:opacity-20 transition-opacity duration-300"></div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Competencies;
