import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaQuoteLeft, FaQuoteRight, FaCoffee, FaRobot, FaMagic, FaBug, FaLaughSquint } from 'react-icons/fa';

const testimonials = [
  {
    quote: "Pravin automated my life. I don't even need to breathe manually anymore.",
    author: "Former Breather, Now Automated",
    icon: FaRobot,
    funFact: "Side effect: occasional robot dance moves during meetings."
  },
  {
    quote: "If Pravin can't fix it, it's probably a feature, not a bug.",
    author: "Frustrated Developer Turned Believer",
    icon: FaBug,
    funFact: "Now writes bug reports in haiku form for Pravin's amusement."
  },
  {
    quote: "I thought automation was just for factories. Turns out, Pravin can automate my coffee breaks too!",
    author: "Coffee Enthusiast",
    icon: FaCoffee,
    funFact: "Caffeine levels have reached 'vibrating through walls' status."
  },
  {
    quote: "The only HR guy who can fix my computer and my career path. Legend.",
    author: "HR Department Survivor",
    icon: FaMagic,
    funFact: "Rumor has it, Pravin's 'magic wand' is just a cleverly disguised USB stick."
  },
  {
    quote: "He's like the Swiss Army knife of tech and HR. One minute he's coding, the next he's fixing my printer.",
    author: "Perpetually Confused Employee",
    icon: FaRobot,
    funFact: "Still can't decide if Pravin is human or an extremely advanced AI."
  },
  {
    quote: "Pravin's automations are so good, I'm convinced he has future vision. Or he's just that good.",
    author: "Tech Guru Wannabe",
    icon: FaMagic,
    funFact: "Tried to automate crystal ball gazing. Results pending."
  },
];

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

const TestimonialCard = ({ quote, author, icon: Icon, funFact }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <motion.div
      className="bg-gray-800 rounded-lg shadow-lg overflow-hidden cursor-pointer h-64"
      variants={cardVariants}
      whileHover={{ scale: 1.05 }}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <AnimatePresence initial={false} mode="wait">
        {!isFlipped ? (
          <motion.div
            key="front"
            className="p-6 h-full flex flex-col justify-between"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div>
              <FaQuoteLeft className="text-yellow-400 text-xl mb-2" />
              <p className="text-lg italic text-gray-300">{quote}</p>
            </div>
            <div className="flex justify-between items-end">
              <p className="text-sm text-yellow-400">- {author}</p>
              <Icon className="text-3xl text-yellow-400" />
            </div>
            <FaQuoteRight className="text-yellow-400 text-xl absolute bottom-6 right-6" />
          </motion.div>
        ) : (
          <motion.div
            key="back"
            className="p-6 h-full bg-yellow-400 flex flex-col justify-center items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <FaLaughSquint className="text-4xl text-gray-800 mb-4" />
            <p className="text-lg font-bold text-gray-900 text-center">{funFact}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const Testimonials = () => {
  return (
    <section id="testimonials" className="min-h-screen bg-gradient-to-b from-gray-800 to-black text-white py-12 px-4">
      <motion.h2
        className="text-3xl sm:text-4xl font-bold text-center text-yellow-400 mb-8 sm:mb-12"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        What They Say
        <motion.span 
          className="text-base block"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          (Click to Uncover the "Real" Story)
        </motion.span>
      </motion.h2>

      <div className="max-w-6xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {testimonials.map((testi, index) => (
          <motion.div
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
          >
            <TestimonialCard {...testi} />
          </motion.div>
        ))}
      </div>

      <motion.p
        className="text-center text-gray-400 mt-12 italic"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1, duration: 0.5 }}
      >
        * Warning: Excessive exposure to Pravin's automation skills may result in spontaneous productivity and uncontrollable urges to optimize everything.
      </motion.p>
    </section>
  );
};

export default Testimonials;