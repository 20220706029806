import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBookOpen, FaTools, FaChartLine, FaPlay, FaDownload, FaSearch } from 'react-icons/fa';
import Footer from './Footer';

const resources = {
    ebooks: [
      {
        title: "Digital Transformation Guide",
        description: "Learn how to digitize and automate your business with real-world examples.",
        format: "PDF, 50 pages",
        downloadLink: "/downloads/digital-transformation-guide.pdf"
      },
      {
        title: "AI for Business",
        description: "A detailed whitepaper on how AI and machine learning can optimize your workflows.",
        format: "PDF, 30 pages",
        downloadLink: "/downloads/ai-for-business.pdf"
      }
    ],
    templates: [
      {
        title: "Project Management Template (Excel)",
        description: "Organize and manage your projects with our customizable Excel template.",
        format: "Excel Template",
        downloadLink: "/downloads/project-management-template.xlsx"
      },
      {
        title: "Automation Scripts",
        description: "Pre-built Python scripts for automating repetitive tasks in your business.",
        format: "Python Scripts",
        downloadLink: "/downloads/automation-scripts.zip"
      }
    ],
    caseStudies: [
      {
        title: "Zomato Case Study",
        description: "Learn how we optimized Zomato’s backend system and reduced delivery delays by 20%.",
        link: "/case-studies/zomato"
      },
      {
        title: "Byju's Case Study",
        description: "Find out how we helped Byju's develop an AI-driven recommendation engine.",
        link: "/case-studies/byjus"
      }
    ],
    videos: [
      {
        title: "Automate Your Business with Python",
        description: "Step-by-step guide on how to automate repetitive business tasks using Python.",
        format: "Video (30 minutes)",
        link: "/videos/python-automation"
      },
      {
        title: "Build Scalable Cloud Infrastructure",
        description: "Learn how to build scalable cloud infrastructure for your business.",
        format: "Video (45 minutes)",
        link: "/videos/cloud-infrastructure"
      }
    ]
  };

const ResourceCard = ({ resource, type }) => {
  const [isHovered, setIsHovered] = useState(false);

  const iconMap = {
    ebooks: FaBookOpen,
    templates: FaTools,
    caseStudies: FaChartLine,
    videos: FaPlay
  };

  const Icon = iconMap[type];

  return (
    <motion.div 
      className="bg-gray-800 h-80 w-full p-6 rounded-lg shadow-lg relative cursor-pointer overflow-hidden"
      whileHover={{ scale: 1.05 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <Icon className="text-4xl text-yellow-400 mb-4" />
      <h4 className="text-2xl font-bold mb-2 text-yellow-400">{resource.title}</h4>
      <p className="text-gray-300 mb-2">{resource.description}</p>
      <p className="text-gray-400 mb-4">{resource.format}</p>
      <motion.a
        href={resource.downloadLink || resource.link}
        className="inline-block bg-yellow-400 absolute bottom-4 text-gray-900 px-6 py-3 rounded-full font-bold text-lg hover:bg-yellow-300 transition-colors"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        download={type === 'ebooks' || type === 'templates'}
      >
        {type === 'videos' ? 'Watch Now' : type === 'caseStudies' ? 'Read More' : 'Download Now'}
      </motion.a>
      <AnimatePresence>
        {isHovered && (
          <motion.div
            className="absolute inset-0 bg-black bg-opacity-80 flex items-center justify-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <FaDownload className="text-6xl text-yellow-400" />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const ResourcesPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState('all');

  const filteredResources = Object.entries(resources).flatMap(([category, items]) => 
    items.filter(item => 
      (activeCategory === 'all' || category === activeCategory) &&
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    ).map(item => ({ ...item, category }))
  );

  return (
    <>
    <section id="resources" className="bg-gradient-to-b from-gray-900 to-black text-white py-16 px-4 min-h-screen">
      <div className="container mx-auto">
        <motion.h2 
          className="text-5xl font-bold text-center mb-4"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Unlock Your Business Potential
        </motion.h2>
        <motion.p 
          className="text-xl text-center mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Dive into our treasure trove of resources and level up your game!
        </motion.p>

        {/* Search and Filter */}
        <div className="mb-8 flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="relative">
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search resources..."
              className="pl-10 pr-4 py-2 rounded-full bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-yellow-400"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="flex space-x-2">
            {['all', 'ebooks', 'templates', 'caseStudies', 'videos'].map(category => (
              <button
                key={category}
                className={`px-4 py-2 rounded-full ${activeCategory === category ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800 text-white'}`}
                onClick={() => setActiveCategory(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Resources Grid */}
        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          layout
        >
          <AnimatePresence>
            {filteredResources.map((resource, index) => (
              <motion.div
                key={resource.title}
                layout
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
              >
                <ResourceCard resource={resource} type={resource.category} />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>

        {/* Newsletter CTA */}
        <motion.section 
          className="mt-16 bg-gradient-to-r from-yellow-400 to-yellow-500 text-gray-900 py-16 rounded-lg"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <div className="container mx-auto text-center">
            <h2 className="text-4xl font-bold">Stay Ahead of the Curve</h2>
            <p className="text-lg mt-4">Subscribe for exclusive content, insider tips, and first dibs on new resources!</p>
            <div className="mt-6 flex justify-center">
              <input type="email" className="p-4 rounded-l-lg shadow-lg text-gray-900 w-64" placeholder="Enter your email" />
              <button className="bg-gray-900 text-yellow-400 px-8 py-4 rounded-r-lg font-semibold shadow-lg hover:bg-gray-800 transition-colors">
                Join the Elite
              </button>
            </div>
          </div>
        </motion.section>

      </div>
    </section>
    <Footer/>
    </>
  );
};

export default ResourcesPage;