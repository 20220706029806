import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaRobot, FaCoffee, FaLaptop, FaBrain, FaRocket } from 'react-icons/fa';

const blogPosts = [
  {
    id: 1,
    title: "Confessions of a Code-Addicted HR Manager",
    date: "June 15, 2024",
    icon: FaRobot,
    snippet: "My journey from spreadsheet wrangler to Python charmer. Spoiler: The robots haven't taken over... yet.",
  },
  {
    id: 2,
    title: "The Day AI Took Over the Office (And Why That's Okay)",
    date: "July 22, 2024",
    icon: FaLaptop,
    snippet: "When the machines rise, make sure they know how to file your expense reports.",
  },
  {
    id: 3,
    title: "VR Team-Building: When 'Trust Fall' Becomes 'Trust Fail'",
    date: "August 5, 2024",
    icon: FaBrain,
    snippet: "We built a VR platform for team-building. Chaos ensued. Lessons were learned. No pixels were harmed in the process.",
  },
  {
    id: 4,
    title: "The Tao of Tech: Balancing Bits and Human Bits",
    date: "September 12, 2024",
    icon: FaCoffee,
    snippet: "Juggling HR, IT, and sanity: A guide to surviving the corporate tech-tonic shift.",
  },
  {
    id: 5,
    title: "The Automation Revolution: Rise of the Machines (and Longer Coffee Breaks)",
    date: "October 1, 2024",
    icon: FaRocket,
    snippet: "How I automated my way to productivity nirvana and why my coffee mug now has AI.",
  },
];

const BlogCard = ({ post, setHoveredPost }) => (
  <motion.div
    className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-yellow-400/20 transition-all duration-300"
    whileHover={{ scale: 1.05, rotate: 1 }}
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    onMouseEnter={() => setHoveredPost(post.id)}
    onMouseLeave={() => setHoveredPost(null)}
  >
    <div className="flex items-center mb-4">
      <post.icon className="text-3xl text-yellow-400 mr-3" />
      <h2 className="text-2xl font-bold text-yellow-400">{post.title}</h2>
    </div>
    <p className="text-gray-400 text-sm mb-3">{post.date}</p>
    <p className="text-lg text-gray-300 mb-4">{post.snippet}</p>
    <Link
      to={`/blog/${post.id}`}
      className="text-yellow-400 inline-block hover:underline"
    >
      Dive into the Madness →
    </Link>
  </motion.div>
);

const Blog = () => {
  const [hoveredPost, setHoveredPost] = useState(null);

  return (
    <section className="min-h-screen bg-gray-900 text-white p-6 sm:p-12">
      <div className="max-w-6xl mx-auto">
        <motion.h1 
          className="text-5xl font-bold text-center text-yellow-400 mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Adventures in Tech & HR
        </motion.h1>
        
        <motion.div 
          className="mb-8 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Link
            to="/"
            className="inline-block bg-yellow-400 text-gray-900 px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-yellow-500 transition-all"
          >
            ← Back to Reality
          </Link>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {blogPosts.map(post => (
            <div key={post.id}>
              <BlogCard post={post} setHoveredPost={setHoveredPost} />
              <AnimatePresence>
                {hoveredPost === post.id && (
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    className="mt-2 text-yellow-400 text-sm italic"
                  >
                    Warning: Reading may cause uncontrollable urges to automate everything
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;