import React, { useState, useRef, useEffect } from "react";
import { motion, useAnimation, useInView, AnimatePresence } from "framer-motion";
import {
  FaLaptopCode,
  FaPalette,
  FaMobileAlt,
  FaRocket,
  FaShieldAlt,
  FaCloudUploadAlt,
  FaBrain,
  FaChartLine,
  FaDatabase,
  FaStar,
  FaArrowRight,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";

const services = [
    {
      title: "Full-Stack Web Development",
      description:
        "Building scalable, responsive web applications that transform your vision into a digital masterpiece.",
      icon: FaLaptopCode,
      color: "from-blue-400 to-blue-600",
    },
    {
      title: "UI/UX Design",
      description:
        "We design intuitive interfaces that keep users hooked and coming back for more.",
      icon: FaPalette,
      color: "from-purple-400 to-purple-600",
    },
    {
      title: "Mobile App Development",
      description:
        "From concept to launch, we create mobile apps that drive user engagement and boost your bottom line.",
      icon: FaMobileAlt,
      color: "from-green-400 to-green-600",
    },
    {
      title: "SEO & Digital Marketing",
      description:
        "Maximize your online visibility with cutting-edge SEO and digital marketing strategies that get you noticed.",
      icon: FaRocket,
      color: "from-red-400 to-red-600",
    },
    {
      title: "Cybersecurity Solutions",
      description:
        "Fortify your business with advanced security solutions that keep your data safe from threats.",
      icon: FaShieldAlt,
      color: "from-yellow-400 to-yellow-600",
    },
    {
      title: "Cloud Architecture & DevOps",
      description:
        "Optimize your infrastructure with cloud solutions that scale with your business needs.",
      icon: FaCloudUploadAlt,
      color: "from-indigo-400 to-indigo-600",
    },
    {
      title: "AI & Machine Learning",
      description:
        "Infuse AI into your business processes for smarter, more efficient decision-making.",
      icon: FaBrain,
      color: "from-pink-400 to-pink-600",
    },
    {
      title: "Data Analytics & Visualization",
      description:
        "Turn raw data into valuable insights that guide your business decisions.",
      icon: FaChartLine,
      color: "from-green-500 to-blue-500",
    },
    {
      title: "Database Design & Management",
      description:
        "Efficiently structured databases that power your business's data needs.",
      icon: FaDatabase,
      color: "from-orange-400 to-red-500",
    },
  ];
  
  const portfolioItems = [
    {
      name: "Zomato",
      logo: "/Zomato_logo.png",
      description:
        "Improved their backend delivery system, reducing delivery delays by 20%.",
      challenge:
        "Zomato was facing issues with their backend delivery system, causing frequent delays during peak hours.",
      solution:
        "We optimized the system with a microservices architecture and implemented real-time data processing for accurate delivery predictions.",
      results:
        "Delivery delays were reduced by 20%, and the system now handles 30% more orders without crashing during high traffic.",
    },
    {
      name: "UrbanClap",
      logo: "/UrbanClap_Logo.png",
      description:
        "Revamped the mobile app experience, increasing user engagement by 30%.",
      challenge:
        "UrbanClap's mobile app had a high bounce rate due to a non-intuitive user interface and frequent crashes.",
      solution:
        "We revamped the app's UI/UX and optimized the codebase, ensuring smooth performance across devices.",
      results:
        "User engagement increased by 30%, and app crashes were reduced by 40%, making the user experience seamless and reliable.",
    },
    {
      name: "Byju's",
      logo: "/Byjus-Logo.png",
      description:
        "Developed an AI-driven recommendation engine for personalized learning, boosting student retention by 15%.",
      challenge:
        "Byju’s needed to personalize learning experiences based on student performance data.",
      solution:
        "We built an AI-driven recommendation engine that analyzed user behavior and provided tailored learning paths.",
      results:
        "Student retention increased by 15%, and users spent 25% more time on the platform, engaging with recommended content.",
    },
    {
      name: "OYO Rooms",
      logo: "/OYO_Rooms_logo.png",
      description:
        "Implemented a cloud-based property management system, reducing overhead costs by 25%.",
      challenge:
        "OYO needed a centralized system to manage its expanding property network, reduce overhead costs, and improve booking accuracy.",
      solution:
        "We developed a cloud-based property management system that integrated real-time booking data and automated property listings.",
      results:
        "OYO reduced operational overhead by 25%, and booking errors decreased by 18%, improving overall customer satisfaction.",
    },
    {
      name: "Swiggy",
      logo: "/Swiggy-logo.png",
      description:
        "Built a scalable customer service platform, reducing response time by 40%.",
      challenge:
        "Swiggy’s customer service system was overwhelmed by the rapid growth in order volume, leading to delayed responses.",
      solution:
        "We built a scalable, AI-driven customer service platform that automated common queries and routed complex issues to the right teams.",
      results:
        "Customer service response times were reduced by 40%, and Swiggy saw a 15% improvement in customer satisfaction scores.",
    },
  ];
  

const ServiceCard = ({ service, onHover }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      className={`bg-gradient-to-br ${service.color} p-6 rounded-lg shadow-xl cursor-pointer overflow-hidden`}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
      }}
      initial="hidden"
      animate={controls}
      whileHover={{ scale: 1.05, rotate: 1 }}
      onHoverStart={() => onHover(service.title)}
      onHoverEnd={() => onHover(null)}
    >
      <motion.div
        className="text-4xl text-white mb-4"
        whileHover={{ rotate: 360, scale: 1.2 }}
        transition={{ duration: 0.5 }}
      >
        <service.icon />
      </motion.div>
      <h3 className="text-xl font-bold text-white mb-2">{service.title}</h3>
      <p className="text-white text-opacity-90">{service.description}</p>
    </motion.div>
  );
};

const ScrollingLogos = ({ companies }) => {
  return (
    <div className="overflow-hidden whitespace-nowrap my-8">
      <motion.div
        className="inline-block"
        animate={{ x: [0, -100 + "%"] }}
        transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
      >
        {companies.concat(companies).map((company, index) => (
          <div key={index} className="inline-block mx-8">
            <img
              src={company.logo}
              alt={`${company.name} logo`}
              className="w-24 h-24 object-contain"
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

const CaseStudy = ({ company, onClose }) => {
    return (
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="bg-gray-800 p-8 rounded-lg max-w-2xl w-full"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
        >
          <h2 className="text-3xl font-bold text-yellow-400 mb-4">{company.name} Case Study</h2>
          <img src={company.logo} alt={`${company.name} logo`} className="w-24 h-24 object-contain mb-4" />
          <p className="text-white mb-4">{company.description}</p>
  
          <h3 className="text-xl font-bold text-yellow-400 mb-2">Challenge</h3>
          <p className="text-white mb-4">{company.challenge}</p>
  
          <h3 className="text-xl font-bold text-yellow-400 mb-2">Solution</h3>
          <p className="text-white mb-4">{company.solution}</p>
  
          <h3 className="text-xl font-bold text-yellow-400 mb-2">Results</h3>
          <p className="text-white mb-4">{company.results}</p>
  
          <button
            onClick={onClose}
            className="bg-yellow-400 text-gray-900 px-4 py-2 rounded-full font-bold mt-4"
          >
            Close
          </button>
        </motion.div>
      </motion.div>
    );
  };
  

const Services = () => {
  const [hoveredService, setHoveredService] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const navigate = useNavigate();

  return (
    <>
      <section id="services" className="bg-gray-900 text-white py-16 px-4 min-h-screen">
        <div className="container mx-auto">
          <motion.h2
            className="text-4xl font-bold text-center mb-4"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Unlock the Full Potential of Your Business
          </motion.h2>
          <motion.p
            className="text-xl text-center mb-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            (We build, we secure, we scale – You grow.)
          </motion.p>

          {/* Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            {services.map((service, index) => (
              <ServiceCard key={index} service={service} onHover={setHoveredService} />
            ))}
          </div>

          {/* Scrolling Logos */}
          <ScrollingLogos companies={portfolioItems} />

          {/* Case Studies */}
          <motion.h3
            className="text-3xl font-bold text-center mb-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7, duration: 0.5 }}
          >
            Success Stories
          </motion.h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
            {portfolioItems.map((company, index) => (
              <motion.div
                key={index}
                className="bg-gray-800 p-6 rounded-lg shadow-xl cursor-pointer"
                whileHover={{ scale: 1.05 }}
                onClick={() => setSelectedCompany(company)}
              >
                <img
                  src={company.logo}
                  alt={`${company.name} logo`}
                  className="w-24 h-24 object-contain mb-4 mx-auto"
                />
                <h4 className="text-lg font-bold text-yellow-400 mb-2">{company.name}</h4>
                <p className="text-gray-300">{company.description}</p>
                <motion.div
                  className="mt-4 text-blue-400 flex items-center"
                  whileHover={{ x: 5 }}
                >
                  View Case Study <FaArrowRight className="ml-2" />
                </motion.div>
              </motion.div>
            ))}
          </div>

          {/* CTAs */}
          <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
            <motion.button
              onClick={() => navigate("/contact")}
              className="bg-yellow-400 text-gray-900 px-8 py-3 rounded-full font-bold text-lg"
              whileHover={{ scale: 1.05, backgroundColor: "#FBBF24" }}
              whileTap={{ scale: 0.95 }}
            >
              Get a Free Consultation
            </motion.button>
            <motion.button
              onClick={() => navigate("/contact")}
              className="bg-green-500 text-white px-8 py-3 rounded-full font-bold text-lg"
              whileHover={{ scale: 1.05, backgroundColor: "#34D399" }}
              whileTap={{ scale: 0.95 }}
            >
              Let's Work Together
            </motion.button>
          </div>
        </div>

        <AnimatePresence>
          {hoveredService && (
            <motion.div
              className="fixed bottom-8 left-0 right-0 bg-yellow-400 text-gray-900 py-2 text-center"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
            >
              <p className="font-bold">
                Pro Tip: {hoveredService} is the key to scaling your business fast!
              </p>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {selectedCompany && (
            <CaseStudy company={selectedCompany} onClose={() => setSelectedCompany(null)} />
          )}
        </AnimatePresence>
      </section>
      <Footer/>
    </>
  );
};

export default Services;