import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Competencies from './components/Competencies';
import Experience from './components/Experience';
import Certifications from './components/Certifications';
import Strengths from './components/Strengths';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Testimonials from './components/Testimonials';
import Blog from './components/Blog'; // Blog Component
import BlogPost from './components/BlogPost'; // BlogPost Component
import Sidebar from './components/Header'; // Floating Sidebar for Navigation
import './index.css';  // Ensure your global styles are imported
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Services from './components/Services';
import ResourcesPage from './components/ResourcesPage';

function App() {
  return (
    <Router>
      <div id="root" className="flex flex-col min-h-screen bg-gray-900">
        {/* Sidebar for Navigation */}
        <Sidebar />

        {/* Main content section */}
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={
              <>
                <Header />
                <Hero />
                <About />
                <Competencies />
                <Experience />
                <Certifications />
                <Strengths />
                <Testimonials />
                <Contact />
                <Footer />
              </>
            } />

            {/* Blog and Blog Post routes */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog/:id" element={<BlogPost />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/resources" element={<ResourcesPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
