import React, { useState, useEffect, useRef, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, Stars } from "@react-three/drei";
import Typewriter from "typewriter-effect";
import { Color } from "three";
import * as THREE from 'three';
import FloatingSkillRing from "./FloatingSkillRing";
import { FaRobot, FaLaptopCode, FaMagic } from 'react-icons/fa';

// Optimized Floating Object
const FloatingObject = ({ position, scale, speed, geometry, color }) => {
  const meshRef = useRef();
  useFrame(({ clock }) => {
    const t = clock.getElapsedTime();
    meshRef.current.position.y = position[1] + Math.sin(t * speed) * 0.5;
    meshRef.current.rotation.x = t * speed;
    meshRef.current.rotation.y = t * speed * 0.5;
  });

  return (
    <mesh ref={meshRef} scale={scale} position={position}>
      {geometry}
      <meshStandardMaterial color={color || "#FFDD57"} wireframe />
    </mesh>
  );
};

// Optimized Particle System
const Particles = ({ count }) => {
  const particlesRef = useRef();
  const [positions, colors] = useMemo(() => {
    const positions = new Float32Array(count * 3);
    const colors = new Float32Array(count * 3);
    for (let i = 0; i < count; i++) {
      positions[i * 3] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 10;
      positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
      const color = new Color(`hsl(${Math.random() * 360}, 100%, 75%)`);
      color.toArray(colors, i * 3);
    }
    return [positions, colors];
  }, [count]);

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    for (let i = 0; i < count; i++) {
      positions[i * 3] += Math.sin(elapsedTime + i) * 0.01;
      positions[i * 3 + 1] += Math.cos(elapsedTime + i) * 0.01;
    }
    particlesRef.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <points ref={particlesRef}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          count={count}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attach="attributes-color"
          count={count}
          array={colors}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial size={0.1} vertexColors />
    </points>
  );
};



const Hero = () => {
  const [secretRevealed, setSecretRevealed] = useState(false);
  const [currentTaglineIndex, setCurrentTaglineIndex] = useState(0);

  // Taglines with changing words
  const taglines = [
    {
      text: "Always",
      words: [
        "coding up storms",
        "automating reality",
        "innovating new ideas",
        "learning something weird",
        "solving bugs with style",
      ],
    },
    {
      text: "Master of",
      words: [
        "organized chaos",
        "creative solutions",
        "HR tech wizardry",
        "strategic automation",
        "unplanned caffeine intake",
      ],
    },
    {
      text: "Driven by",
      words: [
        "coffee, sarcasm",
        "endless curiosity",
        "an unhealthy obsession with code",
        "a desire to optimize everything",
        "a love of problem-solving",
      ],
    },
    {
      text: "Curious about",
      words: [
        "space, the final frontier",
        "the mysteries of the universe",
        "new ways to code",
        "philosophical questions",
        "the future of automation",
      ],
    },
    {
      text: "Juggling",
      words: [
        "HR and IT",
        "code and coffee",
        "management and madness",
        "robots and reality",
        "technology and creativity",
      ],
    },
    {
      text: "I'm always busy",
      words: [
        "coding something new",
        "dreaming of automation",
        "improving workflows",
        "testing my patience with tech",
      ],
    },
    {
      text: "I'm a master of",
      words: [
        "solving impossible problems",
        "making the complex seem simple",
        "automating tasks that seem boring",
        "finding humor in debugging",
      ],
    },
    {
      text: "Fueled by",
      words: [
        "caffeine and code",
        "curiosity and contemplation",
        "endless passion for tech",
        "a good cup of coffee and a challenge",
      ],
    },
    {
      text: "Day or night, I'm",
      words: [
        "optimizing everything",
        "automating processes",
        "exploring new ideas",
        "tackling complex projects",
      ],
    },
  ];

  useEffect(() => {
    const taglineInterval = setInterval(() => {
      setCurrentTaglineIndex((prevIndex) => (prevIndex + 1) % taglines.length);
    }, 15000);

    return () => clearInterval(taglineInterval);
  }, []);

  const handleSecretClick = () => {
    setSecretRevealed(!secretRevealed);
  };
  

  
  const FloatingIcons = () => {
    const icons = [FaRobot, FaLaptopCode, FaMagic];
    return (
      <div className="absolute top-0 right-0 bottom-0 left-0 pointer-events-none">
        {icons.map((Icon, index) => (
          <motion.div
            key={index}
            className="absolute"
            initial={{ x: Math.random() * window.innerWidth, y: 100 }}
            animate={{
              y: window.innerHeight + 50,
              rotate: 360,
              transition: { duration: 20 + Math.random() * 10, repeat: Infinity, ease: "linear" }
            }}
          >
            <Icon className="text-yellow-400 text-4xl" />
          </motion.div>
        ))}
      </div>
    );
  };

  const [funFact, setFunFact] = useState("");
  const funFacts = [
    "I once debugged a program by staring at it intensely. The bug got scared and fixed itself.",
    "My code is so clean, it squeaks when you scroll.",
    "I don't always test my code, but when I do, I do it in production.",
    "I'm not arguing, I'm just explaining why I'm right about this code structure.",
    "My code doesn't have bugs, it just develops random features.",
  ];

  const generateFunFact = () => {
    const newFact = funFacts[Math.floor(Math.random() * funFacts.length)];
    setFunFact(newFact);
  };

  return (
    <section
      id="hero"
      className="relative min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-black to-gray-900 text-white p-6 sm:p-8 overflow-hidden"
    >
      {/* 3D Background */}
      <div className="absolute inset-0 z-0">
        <Canvas camera={{ position: [0, 0, 5] }}>
          <ambientLight intensity={0.3} />
          <pointLight position={[10, 10, 10]} />
          <FloatingObject position={[10, 0, -2]} scale={0.8} speed={0.2} geometry={<torusGeometry args={[1, 0.3, 20, 10]} />} />
          <FloatingObject position={[12, 2, -5]} scale={1.2} speed={0.5} geometry={<dodecahedronGeometry args={[1, 0]} />} />
          <FloatingObject position={[10, -5, -4]} scale={0.6} speed={0.8} geometry={<octahedronGeometry args={[1, 0]} />} />
          <Particles count={10} />
          <Stars radius={100} depth={50} count={5000} factor={4} fade speed={2} />
          <FloatingSkillRing position={[-6, 2, -1]} scale={1} />
          <OrbitControls enableZoom={false} enablePan={false} />
        </Canvas>
      </div>

      {/* <FloatingIcons /> */}
     

      {/* Content */}
      <motion.div
        className="relative z-10 text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <motion.img
          src="/prv_img.jpeg"
          alt="Pravin Malviya"
          className="rounded-full h-32 w-32 sm:h-40 sm:w-40 lg:h-56 lg:w-56 border-8 border-yellow-400 mx-auto object-cover shadow-xl "
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1, ease: "easeOut" }}
        />

        <motion.h1
          className="text-3xl sm:text-4xl lg:text-6xl font-extrabold mt-6 tracking-wide"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.span className="text-yellow-400">
            Pravin Malviya
          </motion.span>
        </motion.h1>
        <div className="border-b-2 py-1 block border-gray-500"></div>
        <motion.p className="text-lg sm:text-xl lg:text-2xl mt-4 text-gray-300 font-semibold flex items-center justify-center flex-wrap">
          <span>{taglines[currentTaglineIndex].text}</span>
          <motion.span className="text-yellow-400 mx-2 inline-block min-w-[200px]">
            <Typewriter
              options={{
                strings: taglines[currentTaglineIndex].words,
                autoStart: true,
                loop: true,
                delay: 60,
                deleteSpeed: 3,
              }}
            />
          </motion.span>
        </motion.p>
{/* 
        <motion.p
          className="text-sm sm:text-md lg:text-lg mt-4 text-gray-400 italic"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.4 }}
        >
          If you can't automate it, you're probably doing it wrong.
        </motion.p> */}

        <motion.a
          href="https://www.linkedin.com/in/prvmalviya"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-yellow-400 text-gray-900 px-4 py-2 sm:px-6 sm:py-3 lg:px-10 lg:py-4 mt-4 sm:mt-6 lg:mt-8 rounded-full inline-block font-semibold shadow-lg hover:bg-yellow-500 transition-all"
          whileHover={{ scale: 1.05, rotate: 3 }}
          whileTap={{ scale: 0.95 }}
        >
          Let's Connect Before I Automate the World
        </motion.a>

        <motion.button
          className="mt-4 ml-4 bg-purple-500 text-gray-900 py-2 px-4 sm:px-6 sm:py-3 lg:px-10 lg:py-4 sm:mt-6 lg:mt-8 rounded-full inline-block font-semibold shadow-lg hover:bg-yellow-500 transition-all"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={generateFunFact}
        >
          Generate Fun Fact!
        </motion.button>

        <AnimatePresence>
          {funFact && (
            <motion.p
              className="mt-4 text-yellow-400 italic"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
            >
              {funFact}
            </motion.p>
          )}
        </AnimatePresence>
        
      </motion.div>

      {/* Fun Witty Section */}
      <motion.div
        className="relative z-10 text-center mt-6 sm:mt-8 lg:mt-12"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
      >
        <p className="text-sm sm:text-md lg:text-lg text-gray-400">
          "Making tech work so smoothly, you'll think I've automated myself."
        </p>
        <p className="text-xs sm:text-sm lg:text-base mt-4 text-yellow-400 italic">
          (Also available for philosophical debates on automation, coffee, and
          the meaning of life.)
        </p>
      </motion.div>

      {/* Easter Egg Section */}
      <motion.div
        className="flex justify-center my-2 bg-gray-800 text-center text-yellow-400 px-4 py-2 rounded-full text-xs sm:text-sm lg:text-base cursor-pointer z-20"
        onClick={handleSecretClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <p>🤫 Hover here for a secret...</p>
      </motion.div>

      {/* Secret Message Reveal */}
      <AnimatePresence>
        {secretRevealed && (
          <motion.div
            className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-90 flex flex-col justify-center items-center z-50 p-6 sm:p-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-yellow-400 text-gray-900 p-6 rounded-lg shadow-xl text-center max-w-md"
              initial={{ scale: 0.5, y: -100 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.5, y: 100 }}
              transition={{ type: "spring", damping: 15 }}
            >
              <h2 className="text-3xl font-bold mb-4">🎉 Congratulations! 🎉</h2>
              <p className="text-md sm:text-lg lg:text-xl font-semibold">
                <Typewriter
                  options={{
                    strings: `You've unlocked a hidden truth of the universe: <br />
                "The real magic lies in **automation** (and coffee)." ☕`,
                    autoStart: true,
                    delay: 60,
                    deleteSpeed: 1,
                  }}
                />
              </p>
              <p className="text-sm sm:text-md text-gray-800 mt-4 italic">
                You're now 10% more productive, 20% more caffeinated, and 100% more awesome!
              </p>
              <motion.button
                onClick={handleSecretClick}
                className="mt-6 px-4 py-2 bg-gray-900 text-yellow-400 rounded-full font-semibold transition-all"
                whileHover={{ scale: 1.05, backgroundColor: "#1a202c" }}
                whileTap={{ scale: 0.95 }}
              >
                Back to Reality (Or Is It?)
              </motion.button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default Hero;