import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaRobot, FaCoffee, FaLaptop, FaBrain, FaRocket } from 'react-icons/fa';

const blogPosts = [
  {
    id: 1,
    title: "Confessions of a Code-Addicted HR Manager",
    date: "June 15, 2024",
    icon: FaRobot,
    snippet: "My journey from spreadsheet wrangler to Python charmer. Spoiler: The robots haven't taken over... yet.",
    content: `
      Dear Digital Diary,

      Today, I realized I've become what I once feared: a code-addicted HR manager. It all started innocently enough with a simple Excel macro. Next thing I know, I'm whispering sweet nothings to my Python interpreter at 3 AM.

      Highlights of my descent into automation madness:
      
      1. Created a Slack bot that sends motivational quotes. It became sentient and now only quotes 'The Terminator'.
      2. Automated the coffee machine. Now it brews a fresh cup every time I say "I'm tired" within a 10-foot radius.
      3. Built an AI to handle performance reviews. It rated everyone as "Skynet material" and suggested world domination as a KPI.

      Send help... or maybe just more coffee and a new Python library to play with.

      Yours in automation,
      Pravin
    `
  },
  {
    id: 2,
    title: "The Day AI Took Over the Office (And Why That's Okay)",
    date: "July 22, 2024",
    icon: FaLaptop,
    snippet: "When the machines rise, make sure they know how to file your expense reports.",
    content: `
      Breaking News: Local HR Manager Welcomes AI Overlords

      In an unexpected turn of events, our office AI system has taken over all operations. Here's why I'm surprisingly okay with it:

      1. The AI has a better coffee-to-code ratio than I ever did.
      2. It schedules meetings that actually end on time. Revolutionary!
      3. It's solved the age-old mystery of who keeps stealing lunches from the office fridge.
      4. Performance reviews are now done via meme submissions. Productivity is through the roof!

      Sure, it occasionally threatens world domination, but don't we all after a long day?

      Remember: In the AI-pocalypse, the ones who can debug will inherit the Earth.
    `
  },
  {
    id: 3,
    title: "VR Team-Building: When 'Trust Fall' Becomes 'Trust Fail'",
    date: "August 5, 2024",
    icon: FaBrain,
    snippet: "We built a VR platform for team-building. Chaos ensued. Lessons were learned. No pixels were harmed in the process.",
    content: `
      Virtual Reality Team-Building: A Cautionary Tale

      We decided to take our team-building to the next level with VR. Here's what we learned:

      1. In VR, no one can hear you scream "This is not what I meant by 'think outside the box'!"
      2. Virtual trust falls are significantly less effective when someone forgets to plug in the haptic feedback suits.
      3. Our "Escape the Meeting Room" simulation was too realistic. Three employees are still hiding under their desks.
      4. The "Debug Your Coworker's Code" experience has been banned by the Geneva Convention.

      Despite the chaos, team morale has never been higher. Turns out, nothing bonds a team like shared virtual trauma.

      Next week: Augmented Reality Performance Reviews. What could possibly go wrong?
    `
  },
  {
    id: 4,
    title: "The Tao of Tech: Balancing Bits and Human Bits",
    date: "September 12, 2024",
    icon: FaCoffee,
    snippet: "Juggling HR, IT, and sanity: A guide to surviving the corporate tech-tonic shift.",
    content: `
      The Art of Corporate Juggling: HR, IT, and Existential Crises

      Being an HR-IT hybrid is like being a digital-age superhero, minus the cool costume (still working on that). Here's my guide to surviving:

      1. Monday: Resolve HR disputes. Tuesday: Debug the payroll system. Wednesday: Question existence.
      2. Always keep a rubber duck on your desk. It's great for debugging and for practicing difficult conversations.
      3. When in doubt, turn it off and on again. This applies to computers, conversations, and occasionally, your will to live.
      4. Remember: Every tech problem is just a people problem in disguise. Except printers. Printers are pure evil.

      In the end, it's all about finding balance. And if you can't find it, automate it.

      Stay tuned for my next seminar: "Scrum Masters: The Unsung Heroes of Corporate Zen."
    `
  },
  {
    id: 5,
    title: "The Automation Revolution: Rise of the Machines (and Longer Coffee Breaks)",
    date: "October 1, 2024",
    icon: FaRocket,
    snippet: "How I automated my way to productivity nirvana and why my coffee mug now has AI.",
    content: `
      Automation Nation: A Journey to the Promised Land of Productivity

      I've done it. I've reached automation nirvana. Here's how:

      1. Automated my email responses. Now I sound more human in emails than I do in person.
      2. Created an AI that attends meetings for me. It's gotten three promotions.
      3. Developed a machine learning algorithm to predict coffee shortages. It's never wrong.
      4. Built a neural network to generate excuses for missed deadlines. It's worryingly creative.

      The result? Productivity is up 200%, and my coffee breaks now last longer than my actual work hours.

      Remember, friends: Automate responsibly. And always keep a human around to enjoy those coffee breaks.

      Next project: Automating the automation process. It's automations all the way down!
    `
  },
];


const BlogPost = () => {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === parseInt(id));
  
  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <section className="min-h-screen bg-gray-900 text-white p-6 sm:p-12">
      <div className="max-w-3xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex items-center mb-6">
            <post.icon className="text-5xl text-yellow-400 mr-4" />
            <h1 className="text-4xl font-bold text-yellow-400">{post.title}</h1>
          </div>
          <p className="text-gray-400 mb-8">{post.date}</p>
          <div className="prose prose-lg prose-invert">
            {post.content.split('\n\n').map((paragraph, index) => (
              <p key={index} className="mb-4">{paragraph}</p>
            ))}
          </div>
          
          <motion.div 
            className="mt-12"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <Link to="/blog" className="text-yellow-400 hover:underline">
              ← Back to Blog (Before You Automate Your Life Away)
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default BlogPost;