import React from 'react';
import { motion } from 'framer-motion';
import { FaChalkboardTeacher, FaLaptopCode, FaTrophy, FaBriefcase } from 'react-icons/fa';

const experiences = [
  {
    title: 'HR, IT, and Whatever Needs Fixing',
    company: 'Radiant World',
    period: 'March 2024 - Present',
    description: 'I wear many hats—HR, IT, and the occasional firefighter (metaphorically speaking, of course). My mission? Streamline operations, boost productivity, and make sure everything works smoothly—because chaos is overrated.',
    achievements: [
      'Built tech-driven HR strategies so efficient, they practically run themselves. Team morale? Skyrocketed.',
      'Kept the IT infrastructure running without a single "turn it off and on again."',
      'Introduced fancy tools that made everyone wonder how they ever worked without them.'
    ],
    icon: <FaBriefcase />,
  },
  {
    title: 'Manager of EdTech Magic (Ahem, Innovation) and Corporate Affairs',
    company: 'Concept Education System',
    period: 'March 2021 - March 2024',
    description: 'Led a team of web devs, Unity wizards, and animators to create virtual reality magic for students across India. Think "The Matrix" but for learning—without the evil robots.',
    achievements: [
      'Built a virtual reality platform that makes education way more fun than it has any right to be.',
      'Collaborated with government agencies to make digital education actually work (miracles do happen).',
      'Managed a team of geniuses and somehow kept my sanity intact.'
    ],
    icon: <FaChalkboardTeacher />,
  },
  {
    title: 'Independent Tech Consultant & Automation Wizard',
    company: 'Self-Employed',
    period: 'March 2019 - March 2021',
    description: `Provided tech consulting and made automating boring tasks my life's mission. If you're doing something manually, you're doing it wrong.`,
    achievements: [
      'Won the Swachh Technology Challenge because automation solutions are way cooler than they sound.',
      'Designed systems so efficient that people had time to ponder the meaning of life (or maybe just grab an extra coffee).',
      'Built software for local governments that made even bureaucracy look efficient.'
    ],
    icon: <FaLaptopCode />,
  },
  {
    title: 'Sr. Manager (A.K.A. "Automation Genius")',
    company: 'S.D Malviya & Co.',
    period: 'Feb 2011 - Mar 2020',
    description: 'Oversaw tax filings, audits, and financial reporting while automating anything that moved (figuratively speaking). Turned tedious tasks into quick wins using my programming wizardry.',
    achievements: [
      'Built automations so smooth, the team barely noticed they were working. Efficiency skyrocketed, coffee breaks increased.',
      'Handled over 300 tax audits and GST filings like a pro—while the rest of the world panicked about paperwork.',
      'Trained a small army of junior accountants and implemented cloud-based systems, making tax season less of a nightmare for everyone.'
    ],
    icon: <FaTrophy />,
  },
];

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.7, ease: 'easeOut' } },
};

const Experience = () => {
  return (
    <section id="experience" className="min-h-screen bg-gradient-to-b from-gray-800 to-black text-white py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.h2
          className="text-4xl sm:text-5xl font-extrabold text-center text-yellow-400 mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          The Quest for Productivity
        </motion.h2>
        <motion.p
          className="text-xl text-center text-gray-300 mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          (Also known as: Jobs That Didn't Break Me)
        </motion.p>

        <div className="space-y-12">
          {experiences.map((exp, index) => (
            <motion.div
              key={index}
              className="bg-gray-900 rounded-xl shadow-lg overflow-hidden"
              initial="hidden"
              whileInView="visible"
              variants={cardVariants}
              viewport={{ once: true, amount: 0.2 }}
            >
              <div className="md:flex">
                <div className="md:flex-shrink-0 bg-yellow-400 flex items-center justify-center p-6 md:p-8">
                  <div className="text-gray-900 text-4xl md:text-5xl">
                    {exp.icon}
                  </div>
                </div>
                <div className="p-6 md:p-8">
                  <div className="uppercase tracking-wide text-sm text-yellow-400 font-semibold mb-1">{exp.company}</div>
                  <h3 className="text-2xl font-bold text-white mb-2">{exp.title}</h3>
                  <p className="text-gray-400 mb-4">{exp.period}</p>
                  <p className="text-gray-300 mb-4">{exp.description}</p>
                  <h4 className="text-lg font-semibold text-yellow-400 mb-2">Key Achievements:</h4>
                  <ul className="list-disc list-inside text-gray-300 space-y-2">
                    {exp.achievements.map((achievement, i) => (
                      <li key={i}>{achievement}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Experience;