import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaLinkedin,
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaEnvelope,
  FaPaperPlane,
} from "react-icons/fa";
import Footer from "./Footer";

const SocialIcon = ({ Icon, link, color }) => (
  <motion.a
    href={link}
    target="_blank"
    rel="noreferrer"
    className={`text-4xl sm:text-5xl ${color} hover:text-yellow-400 transition-all`}
    whileHover={{ scale: 1.2, rotate: Math.random() * 20 - 10 }}
    whileTap={{ scale: 0.9 }}
  >
    <Icon />
  </motion.a>
);

const Contact = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState(null);

  const contactMethods = [
    {
      name: "LinkedIn",
      icon: FaLinkedin,
      link: "https://www.linkedin.com/in/prvmalviya",
      color: "text-blue-500",
    },
    {
      name: "GitHub",
      icon: FaGithub,
      link: "https://github.com/pravinpanwar",
      color: "text-gray-200",
    },
    {
      name: "Twitter",
      icon: FaTwitter,
      link: "https://twitter.com/prvmalviya",
      color: "text-blue-400",
    },
    {
      name: "Instagram",
      icon: FaInstagram,
      link: "https://instagram.com/pravin.malviya202",
      color: "text-pink-500",
    },
    {
      name: "Facebook",
      icon: FaFacebook,
      link: "https://facebook.com/prv.malviya",
      color: "text-blue-600",
    },
  ];

  const sarcasmLevels = [
    "Mildly Amused",
    "Chuckle-Worthy",
    "Eye-Roll Inducing",
    "Groan-tastic",
    "Full-On Snark",
  ];

  return (
    <>
      <section
        id="contact"
        className="min-h-screen bg-gradient-to-b from-gray-800 to-black p-8 sm:p-12 text-white flex flex-col justify-center items-center"
      >
        <motion.h2
          className="text-4xl sm:text-5xl font-bold text-center text-yellow-400 mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Initiate Human Connection Protocol
        </motion.h2>

        <motion.p
          className="text-lg sm:text-xl text-center text-gray-300 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Warning: Engaging with me may result in severe cases of inspiration,
          uncontrollable laughter, and the urge to automate everything in sight.
        </motion.p>

        <motion.div
          className="space-y-4 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <p className="text-center text-gray-400">
            Select your preferred method of stalking:
          </p>
          <div className="flex justify-center space-x-4">
            {contactMethods.map((method) => (
              <SocialIcon
                key={method.name}
                Icon={method.icon}
                link={method.link}
                color={method.color}
              />
            ))}
          </div>
        </motion.div>

        <motion.div
          className="relative"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <motion.button
            className="bg-yellow-400 text-gray-900 px-6 py-3 rounded-full text-xl font-semibold shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onHoverStart={() => setIsHovering(true)}
            onHoverEnd={() => setIsHovering(false)}
            onClick={() =>
              setSelectedMethod(
                contactMethods[
                  Math.floor(Math.random() * contactMethods.length)
                ]
              )
            }
          >
            {isHovering ? "Brace for Impact!" : "Contact Me (If You Dare)"}
          </motion.button>
          <AnimatePresence>
            {selectedMethod && (
              <motion.div
                className="absolute top-full left-1/2 transform -translate-x-1/2 mt-4 bg-gray-800 p-4 rounded-lg shadow-lg"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                <p className="text-yellow-400 font-semibold mb-2">
                  Congratulations! You've chosen:
                </p>
                <div className="flex items-center space-x-2">
                  <selectedMethod.icon
                    className={`text-2xl ${selectedMethod.color}`}
                  />
                  <span>{selectedMethod.name}</span>
                </div>
                <p className="text-sm text-gray-400 mt-2">
                  Sarcasm Level:{" "}
                  {
                    sarcasmLevels[
                      Math.floor(Math.random() * sarcasmLevels.length)
                    ]
                  }
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>

        <motion.div
          className="mt-12 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 0.5 }}
        >
          <p className="text-gray-400 mb-4">
            Still not satisfied? Fine, here's the nuclear option:
          </p>
          <motion.a
            href="mailto:pravinpanwar@gmail.com"
            className="inline-flex items-center space-x-2 bg-red-500 text-white px-6 py-3 rounded-full text-xl font-semibold shadow-lg hover:bg-red-600 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaEnvelope />
            <span>Launch Email Missile</span>
          </motion.a>
        </motion.div>

        <motion.p
          className="text-sm text-gray-500 mt-8 max-w-md text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          Disclaimer: By contacting Pravin, you agree to potentially become 37%
          more awesome and 100% more likely to laugh at terrible tech puns.
        </motion.p>
      </section>
      {window.location.pathname === "/contact" && <Footer />}
    </>
  );
};

export default Contact;
