import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaCoffee, FaRobot, FaBug, FaMagic } from "react-icons/fa";

const Footer = () => {
  const [coffeeLevel, setCoffeeLevel] = useState(0);
  const [showEasterEgg, setShowEasterEgg] = useState(false);
  const [bugPosition, setBugPosition] = useState({ x: 0, y: 0 });

  const handleCoffeeClick = () => {
    setCoffeeLevel(prev => (prev + 1) % 5);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setBugPosition({
        x: Math.random() * window.innerWidth,
        y: Math.random() * window.innerHeight / 100
      });
    }, 5000);
    console.log('windowInnerwidth : ', window.innerWidth);
    console.log('windowInnerHeight : ', window.innerHeight);
    console.log('bugPosition :', bugPosition);
    return () => clearInterval(interval);
  }, []);

  const coffeeMessages = [
    "Caffeine level: Desperately seeking coffee",
    "Caffeine level: Barely functional",
    "Caffeine level: Coding mode activated",
    "Caffeine level: Bug-squashing beast",
    "Caffeine level: Transcended mortal needs"
  ];

  return (
    <footer className="bg-gray-900 border-t-2 border-gray-400 py-8 h-full sm:py-10 relative overflow-hidden">
      <div className="container mx-auto text-center relative z-10">
        <motion.div
          className="mb-6 cursor-pointer"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleCoffeeClick}
        >
          <FaCoffee className={`text-4xl inline-block transition-colors duration-300 ${
            ['text-gray-500', 'text-yellow-700', 'text-yellow-500', 'text-yellow-300', 'text-yellow-100'][coffeeLevel]
          }`} />
          <motion.p
            key={coffeeLevel}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="text-sm text-gray-400 mt-2"
          >
            {coffeeMessages[coffeeLevel]}
          </motion.p>
        </motion.div>

        <motion.p
          className="text-sm sm:text-md text-gray-400 mb-4 italic"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          "Turning chaos into order... or at least into better chaos." 💻☕
        </motion.p>

        <motion.p
          className="text-xs sm:text-sm text-yellow-400 mb-4 cursor-pointer"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
          onClick={() => setShowEasterEgg(!showEasterEgg)}
        >
          🕵️‍♂️ Psst! Click here for a secret message!
        </motion.p>

        <AnimatePresence>
          {showEasterEgg && (
            <motion.p
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="text-xs sm:text-sm text-green-400 mb-4"
            >
              Congrats! You've discovered the secret chamber of dad jokes. But alas, they've all escaped. Check back later!
            </motion.p>
          )}
        </AnimatePresence>

        <motion.p
          className="text-xs sm:text-sm text-gray-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          &copy; {new Date().getFullYear()} Pravin Malviya. All Rights Reserved.
        </motion.p>
        
        <motion.p
          className="text-xs sm:text-sm text-gray-500 mt-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
        >
          Disclaimer: No robots were harmed in the automation of this website.
        </motion.p>
      </div>
      
      <motion.div
        className="absolute bottom-0 right-0 text-gray-700 opacity-20"
        animate={{ 
          rotate: 360,
          transition: { duration: 20, repeat: Infinity, ease: "linear" }
        }}
      >
        <FaRobot className="text-9xl" />
      </motion.div>

      <motion.div
        className="absolute text-yellow-500 text-2xl"
        initial={bugPosition}
        animate={bugPosition}
        transition={{ duration: 4, repeat: Infinity }}
      >
        <FaBug />
      </motion.div>

      <motion.div
        className="absolute bottom-4 left-0 text-purple-500 opacity-30"
        animate={{
          scale: [1, 1.2, 1],
          rotate: [0, 360, 0],
          transition: { duration: 5, repeat: Infinity }
        }}
      >
        <FaMagic className="text-5xl" />
      </motion.div>
    </footer>
  );
};

export default Footer;