import React, { useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaEnvelope, FaBars, FaBookOpen, FaMagic, FaTimes, FaServicestack, FaHome, FaArchive } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const menuItems = [
  { name: 'Home', icon: FaHome, to: '/', isExternal: true },
  { name: 'Services', icon: FaServicestack, to: '/services' , isExternal: true }, // Added "Services" link
  { name: 'Blog', icon: FaBookOpen, to: '/blog', isExternal: true },
  { name: 'Resources', icon: FaArchive, to: '/resources', isExternal: true },
  { name: 'Contact', icon: FaEnvelope, to: '/contact', isExternal: true },
];

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const location = useLocation();

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const sections = menuItems
      .filter(item => !item.isExternal)
      .map(item => document.getElementById(item.to))
      .filter(Boolean);
    
    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i];
      if (section.offsetTop <= scrollPosition + windowHeight / 2) {
        setActiveItem(menuItems[i].to);
        break;
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isOpen]);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const sidebarVariants = {
    open: { x: 0, width: '250px', transition: { duration: 0.3, type: 'spring', stiffness: 100 } },
    closed: { x: '-100%', width: 0, transition: { duration: 0.3, type: 'spring', stiffness: 100 } },
  };

  return (
    <>
      <motion.button
        className="fixed top-4 left-4 z-50 bg-yellow-400 p-3 rounded-full shadow-lg focus:outline-none"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={toggleSidebar}
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        <FaBars className="text-2xl text-black" />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.aside
            className="fixed top-0 left-0 h-full bg-gradient-to-b from-gray-800 to-black text-white flex flex-col p-6 z-40 shadow-lg"
            variants={sidebarVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <motion.button
              className="absolute top-2 right-2 cursor-pointer text-yellow-400"
              whileHover={{ scale: 1.1, rotate: 180 }}
              onClick={toggleSidebar}
              aria-label="Close menu"
            >
              <FaTimes className="text-2xl" />
            </motion.button>

            <nav className="flex flex-col space-y-6 mt-12">
              {menuItems.map((item) => (
                <motion.div
                  key={item.name}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {item.isExternal ? (
                    <RouterLink
                      to={item.to}
                      className={`group flex items-center p-2 rounded-lg transition-colors duration-300 ${
                        location.pathname === item.to ? 'bg-yellow-400 text-gray-900' : 'hover:bg-gray-700'
                      }`}
                      onClick={toggleSidebar}
                    >
                      <item.icon className="text-2xl group-hover:text-yellow-400 transition duration-300" />
                      <span className="ml-4 text-lg">{item.name}</span>
                    </RouterLink>
                  ) : (
                    <Link
                      to={item.to}
                      smooth={true}
                      duration={1000}
                      className={`group flex items-center p-2 rounded-lg transition-colors duration-300 ${
                        activeItem === item.to ? 'bg-yellow-400 text-gray-900' : 'hover:bg-gray-700'
                      }`}
                      onClick={toggleSidebar}
                    >
                      <item.icon className="text-2xl group-hover:text-yellow-400 transition duration-300" />
                      <span className="ml-4 text-lg">{item.name}</span>
                    </Link>
                  )}
                </motion.div>
              ))}
            </nav>
          </motion.aside>
        )}
      </AnimatePresence>
    </>
  );
};

export default Sidebar;
