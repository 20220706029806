import React, { useState} from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaLaptopCode,
  FaProjectDiagram,
  FaUsers,
  FaGraduationCap,
} from "react-icons/fa";

const InteractiveCard = ({ Icon, title, description, funFact }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <motion.div
      className="relative w-full h-64 cursor-pointer"
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <AnimatePresence initial={false}>
        {!isFlipped ? (
          <motion.div
            key="front"
            className="absolute inset-0 bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-6 flex flex-col justify-between"
            initial={{ rotateY: 180 }}
            animate={{ rotateY: 0 }}
            exit={{ rotateY: 180 }}
            transition={{ duration: 0.3 }}
          >
            <Icon className="text-4xl text-yellow-400 mb-4" />
            <div>
              <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
              <p className="text-sm text-gray-300">{description}</p>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="back"
            className="absolute inset-0 bg-yellow-400 rounded-xl p-6 flex items-center justify-center"
            initial={{ rotateY: -180 }}
            animate={{ rotateY: 0 }}
            exit={{ rotateY: -180 }}
            transition={{ duration: 0.3 }}
          >
            <p className="text-gray-900 font-bold text-center">{funFact}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const About = () => {
  const [showInterests, setShowInterests] = useState(false);

  return (
    <section
      id="about"
      className="bg-gray-900 text-white py-16 px-4 sm:px-6 lg:px-8"
    >
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-yellow-400 mb-2">
            Who Am I?
          </h2>
          <p className="text-xl sm:text-2xl mb-1 text-yellow-400">
            The Legend You Haven't Heard Of (Yet)
          </p>
          <p className="text-lg sm:text-xl text-gray-300 italic mt-4 border-b-2 pb-4 border-gray-200">
            "I'm not saying I'm Batman, but have you ever seen me and a guy who
            solves HR problems while coding in one hand and sipping coffee with
            the other in the same room? Didn't think so."
          </p>
          <div className=""></div>
        </motion.div>

        <motion.div
          className="mt-8 space-y-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <p className="text-base sm:text-lg text-gray-300">
            🎭 Ladies and gentlemen, techies and troublemakers, allow me to
            introduce the main attraction:{" "}
            <span className="text-yellow-400 font-semibold">
              Pravin Malviya
            </span>{" "}
            – your friendly neighborhood HR manager with a not-so-secret double life as a tech enthusiast. Think of me as the Clark Kent of the corporate world, except my superpower is turning caffeine into code and HR policies into happiness.
          </p>
          <p className="text-base sm:text-lg text-gray-300">
            🚀 By day, I'm masquerading as a mild-mannered Human Resources
            Manager at Concept Education System, secretly plotting to make work
            so enjoyable that people forget they're not on vacation. (Don't tell
            the boss, but free ice cream Fridays might be in the works.)
          </p>
          <p className="text-base sm:text-lg text-gray-300">
            🤖 But when the sun goes down and the coffee kicks in, I transform
            into a coding superhero! Armed with Python and JavaScript, I
            automate everything in sight. Need your cat fed while you're away?
            There's an app for that (or there will be once I'm done).
          </p>
          <p className="text-base sm:text-lg text-gray-300">
            🕶️ Oh, did I mention I moonlight as a VR educational content
            creator? That's right, I'm making learning so immersive, students
            might forget they're actually absorbing knowledge. It's like "The
            Matrix," but with fewer machines trying to take over the world (for
            now).
          </p>
          <p className="text-base sm:text-lg text-gray-300">
            🧠 When I'm not busy juggling HR duties, coding up a storm, or
            creating virtual worlds, you'll find me:
            <ul className="list-disc list-inside mt-2">
              <li>
                Solving complex problems (like how to fit more hours of coding
                into a 24-hour day)
              </li>
              <li>
                Pondering the great philosophical questions (like "If a
                programmer fixes a bug and no one is around to see it, does it
                make a sound?")
              </li>
              <li>
                Geeking out over the latest scientific discoveries (Did you know
                that in space, no one can hear you debug?)
              </li>
            </ul>
          </p>
          <p className="text-base sm:text-lg text-gray-300">
            So, whether you need an HR policy revamped, a stubborn piece of code
            tamed, or just someone to debate the merits of tabs vs. spaces, I'm
            your guy. Let's connect and make some digital magic happen!
          </p>
          <p className="text-base sm:text-lg text-gray-300">
            Remember, in a world full of bugs, be the debugger. 🐛🔍
          </p>
          <p className="text-base sm:text-lg text-gray-300 italic">
            P.S. Coffee donations are always welcome. It's not an addiction;
            it's a programming feature.
          </p>
        </motion.div>

        <motion.div
          className="mt-12"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <h3 className="text-2xl sm:text-3xl font-bold text-yellow-400 mb-6">
            Expertise Breakdown
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            <InteractiveCard
              Icon={FaLaptopCode}
              title="Technical Wizardry"
              description="I make Python, JavaScript, and Excel VBA do my bidding. Automation, applications—you name it."
              funFact="I automated my morning coffee machine with a Raspberry Pi. ☕"
            />
            <InteractiveCard
              Icon={FaUsers}
              title="People Whisperer"
              description="I manage humans, even when they don't come with an instruction manual."
              funFact="Once built a team using only a whiteboard and a stack of Post-It notes."
            />
            <InteractiveCard
              Icon={FaProjectDiagram}
              title="Immersive Experience Maestro"
              description="I lead a team of geniuses building virtual reality educational content. We're shaping the future of learning."
              funFact="Made a VR meeting room. The team spends more time customizing avatars than actually attending meetings."
            />
            <InteractiveCard
              Icon={FaGraduationCap}
              title="Learning Junkie"
              description="Whether it's science, art, or philosophy, I'm a lifelong learner always looking to level up."
              funFact="I have more books than furniture. 📚"
            />
          </div>
        </motion.div>

        <motion.div
          className="mt-12"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <motion.button
            className="w-full sm:w-auto bg-yellow-400 text-gray-900 px-6 py-3 rounded-full font-bold text-lg shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowInterests(!showInterests)}
          >
            {showInterests ? "Hide My Quirks" : "Discover My Quirks"}
          </motion.button>

          <AnimatePresence>
            {showInterests && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-6 bg-gray-800 p-6 rounded-lg shadow-lg overflow-hidden"
              >
                <h3 className="text-xl sm:text-2xl font-bold text-yellow-400 mb-4">
                  Things I Do When I'm Not Solving the World's Problems
                </h3>
                <ul className="list-disc list-inside text-gray-300 space-y-3">
                  <li>
                    💻 <strong>Tech Tinkering:</strong> Because who needs sleep
                    when you can automate your coffee maker to judge you for
                    your 3 AM coding sessions?
                  </li>
                  <li>
                    🌌 <strong>Space Stalking:</strong> Obsessively tracking
                    celestial bodies. It's not creepy if they're light-years
                    away, right?
                  </li>
                  <li>
                    📚 <strong>Mind Meddling:</strong> Unraveling the human
                    psyche and pondering existence. Warning: May lead to
                    existential crises at parties.
                  </li>
                  <li>
                    🧘‍♂️ <strong>Professional Sitting:</strong> Achieving inner
                    peace through the ancient art of doing absolutely nothing.
                    Namaste, procrastinators!
                  </li>
                  <li>
                    🔮 <strong>Introverted Scheming:</strong> Plotting world
                    domination from the comfort of my blanket fort. Socializing
                    optional, WiFi essential.
                  </li>
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
