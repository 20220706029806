import React from 'react';
import { motion } from 'framer-motion';
import { FaUserGraduate, FaBusinessTime, FaSmileBeam, FaUsers, FaGlobe } from 'react-icons/fa';

const certifications = [
  {
    name: 'Coaching and Developing Employees',
    institution: 'LinkedIn',
    date: 'May 2023',
    description: `Basically, I've got the skills to turn average Joe into Super Joe.`,
    icon: <FaUserGraduate />,
  },
  {
    name: 'HR as a Business Partner',
    institution: 'LinkedIn',
    date: 'May 2023',
    description: `Because sometimes HR needs to team up with the business like Batman and Robin (guess who's Batman).`,
    icon: <FaBusinessTime />,
  },
  {
    name: 'Human Resources Foundations',
    institution: 'LinkedIn',
    date: 'May 2023',
    description: 'The building blocks of being a Human Resources genius. No cape required, but strongly recommended.',
    icon: <FaSmileBeam />,
  },
  {
    name: 'Managing a Multigenerational Team',
    institution: 'LinkedIn',
    date: 'May 2023',
    description: 'Mastering the art of keeping Gen Z, Millennials, and Boomers from throttling each other.',
    icon: <FaUsers />,
  },
  {
    name: 'Fundamentals of Digital Marketing',
    institution: 'Google Digital Unlocked',
    date: 'Feb 2021',
    description: 'Making the internet bend to my will, one Google ad at a time.',
    icon: <FaGlobe />,
  },
  {
    name: 'Certified Positive Psychology Coach®',
    institution: 'CCE',
    date: 'July 7, 2021',
    description: 'Spent 125 hours learning how to make people feel like superheroes in their own lives.',
    icon: <FaSmileBeam />,
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const cardVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 12,
    },
  },
  hover: { 
    scale: 1.05,
    rotateY: 5,
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
    transition: {
      duration: 0.3,
    },
  },
};

const Certifications = () => {
  return (
    <section id="certifications" className="min-h-screen bg-gradient-to-b from-gray-800 to-black py-16 px-4 sm:px-6 lg:px-8 text-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <motion.h2
          className="text-4xl sm:text-5xl font-extrabold text-center text-yellow-400 mb-4"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Certifications
        </motion.h2>
        <motion.p
          className="text-xl text-center text-gray-300 mb-12"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          (Or Proof I Can Actually Do Stuff)
        </motion.p>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {certifications.map((cert, index) => (
            <motion.div
              key={index}
              className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl shadow-lg overflow-hidden h-full flex flex-col"
              variants={cardVariants}
              whileHover="hover"
            >
              <div className="p-6 relative flex-grow flex flex-col">
                <div className="absolute top-0 right-0 w-20 h-20 bg-yellow-400 rounded-bl-full flex items-center justify-center">
                  <div className="text-gray-900 text-2xl transform -rotate-45">
                    {cert.icon}
                  </div>
                </div>
                <h3 className="text-xl font-bold text-yellow-400 mb-2 pr-16">{cert.name}</h3>
                <p className="text-gray-400 mb-1">{cert.institution}</p>
                <p className="text-sm text-gray-500 mb-4">{cert.date}</p>
                <p className="text-gray-300 italic flex-grow">{cert.description}</p>
              </div>
              <div className="h-2 bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600"></div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Certifications;