import React, { useState } from 'react';
import { FaLightbulb, FaUsers, FaPuzzlePiece, FaClock, FaTasks, FaRocket, FaStar } from 'react-icons/fa';
import { motion } from 'framer-motion';

const strengths = [
  {
    name: 'Analytical Thinking',
    description: `Fancy talk for: I solve puzzles like Sherlock, minus the British accent and hat.`,
    icon: FaPuzzlePiece,
    color: 'from-blue-500 to-purple-500',
    funFact: `Once debugged a program by staring at it intensely. The bug got intimidated and fixed itself.`
  },
  {
    name: 'Leadership',
    description: `Leading people without losing my sanity. Most of the time.`,
    icon: FaUsers,
    color: 'from-green-500 to-teal-500',
    funFact: `My leadership style? Imagine herding cats, but the cats are programmers, and they're all chasing a laser pointer called "deadline".`
  },
  {
    name: 'Adaptability',
    description: `Like a chameleon, but with spreadsheets, code, and a dash of caffeine.`,
    icon: FaLightbulb,
    color: 'from-yellow-400 to-orange-500',
    funFact: `I'm so adaptable, I once wrote code on a napkin when my laptop died. The napkin is now framed in the office.`
  },
  {
    name: 'Time Management',
    description: `Because juggling flaming swords was too easy, I mastered juggling projects instead.`,
    icon: FaClock,
    color: 'from-red-500 to-pink-500',
    funFact: `I've mastered the art of being in three meetings at once. My secret? Really good Wi-Fi and a lot of caffeine.`
  },
  {
    name: 'Creative Problem-Solving',
    description: `You say "problem." I say "challenge accepted, and let's get weird."`,
    icon: FaTasks,
    color: 'from-indigo-500 to-blue-500',
    funFact: `Once solved a coding issue by interpretive dancing the algorithm. The team was confused, but the code worked perfectly.`
  },
  {
    name: 'Futuristic Vision',
    description: `Thinking 10 steps ahead... because 9 just isn't enough.`,
    icon: FaRocket,
    color: 'from-purple-500 to-pink-500',
    funFact: `I predicted the rise of AI assistants. Still waiting for my prediction of code that writes itself while I nap to come true.`
  },
];

const StrengthCard = ({ strength }) => {
  const [isHovered, setIsHovered] = useState(false);
  const Icon = strength.icon;

  const handleClick = () => {
    setIsHovered((prev) => !prev);
  };

  return (
    <motion.div
      className={`relative w-full h-64 rounded-lg overflow-hidden cursor-pointer bg-gradient-to-br ${strength.color}`}
      whileHover={{ scale: 1.05 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <div className="absolute inset-0 p-6 flex flex-col justify-between">
        <div>
          <motion.div
            className="text-5xl text-white mb-4"
            animate={{ rotate: isHovered ? 360 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <Icon />
          </motion.div>
          <h3 className="text-2xl font-bold text-white mb-2">{strength.name}</h3>
          <p className="text-sm text-gray-200">{strength.description}</p>
        </div>
        <motion.div
          className="text-xs text-white mt-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: isHovered ? 1 : 0 }}
        >
          Hover to reveal fun fact
        </motion.div>
      </div>
      <motion.div
        className="absolute inset-0 bg-black bg-opacity-80 p-6 flex items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <p className="text-white text-center">{strength.funFact}</p>
      </motion.div>
    </motion.div>
  );
};



const Strengths = () => {
  return (
    <section id="strengths" className="min-h-screen bg-gradient-to-b from-gray-800 to-black p-8 sm:p-12 text-white">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="text-center mb-12"
      >
        <h2 className="text-4xl sm:text-5xl font-bold text-yellow-400 inline-flex items-center">
          <FaStar className="mr-4" />
          My Superpowers
          <FaStar className="ml-4" />
        </h2>
        <p className="text-xl mt-4 text-gray-300">Hover to unveil the magic!</p>
      </motion.div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 max-w-7xl mx-auto">
        {strengths.map((strength, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <StrengthCard strength={strength} />
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default Strengths;